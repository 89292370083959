<template>
	<div>
		<el-card v-if="!isauthorization">
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-47d637b5-633a-466d-bfef-bbf5b8682fd3.png">
					<div class="info">
						<div class="tit">客户群列表</div>
						<div class="tip">在该页面，你可以看到员工在企业微信端创建的企微客户群聊数据，也可以查看企业管理员在企业微信后台创建的客户群聊数据，统计各群聊成员加入、退出情况。</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="jump">授权安装企店助手</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
				<div class="desc-title">1/同步企业微信创建的客户群</div>
				<img style="display: block;width:100%;max-width: 885px;"
					src="https://cdn.dkycn.cn/melyshop/image/20210804-bc2a6121-491e-4fce-8610-55db4b4b3d13.png"></img>
				<div class="desc-title">2/统计群成员变更趋势</div>
				<img style="display: block;width:100%;max-width: 885px;"
					src="https://cdn.dkycn.cn/melyshop/image/20210802-70fd3273-c6ec-4144-b427-0dc1ed04700f.png"></img>
			</div>
		</el-card>
		<div v-else>
			<el-card shadow="never">
				<div class="blue-box" v-loading="clientLoading">
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{clientNum1}}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">全部客户群数量</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{clientNum2}}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">群内总客户数</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{clientNum3}}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">今日入群客户数</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{clientNum4}}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">今日退群客户数</div>
					</div>
				</div>
			</el-card>
			<el-card shadow="never" style="margin-top:10px">
				<div class="FlexRow" style="justify-content: space-between">
					<div class="FlexRow" style="flex-wrap:wrap">
						<div class="FlexRow" style="margin-bottom:10px">
							<div class="InputFont" style="width:60px;text-align:right;font-weight: bold;color: #606266;">关键字:</div>
							<el-input v-model="groupNmae" placeholder="群名称" style="margin:0px 10px;width:250px">
							</el-input>
						</div>
						<div class="FlexRow" style="margin-bottom:10px">
							<div class="InputFont" style="font-weight: bold;color: #606266;">建群时间:</div>
							<!-- <el-date-picker style="margin:0px 10px;width:200px" v-model="TimeValue1" type="date"
								placeholder="开始日期">
							</el-date-picker>
							<div style="line-height:37px">~</div>
							<el-date-picker style="margin:0px 10px;width:200px" v-model="TimeValue2" type="date"
								placeholder="结束日期">
							</el-date-picker> -->
							<el-date-picker style="margin:0px 10px;width:300px" v-model="timeValue1" type="daterange" range-separator="~"
								start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
						</div>
						<!-- <el-date-picker
              style="margin:0px 10px;width:280px"
              v-model="TimeValue"
              type="daterange"
              :clearable="false"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker> -->
						<div class="FlexRow" style="margin-bottom:10px">
							<div class="InputFont" style="font-weight: bold;color: #606266;">群聊状态:</div>
							<el-select v-model="groupState" placeholder="" style="margin:0px 10px">
								<el-option v-for="v in groupList" :key="v.value" :value="v.value" :label="v.label">
								</el-option>
							</el-select>
						</div>
						<div class="FlexRow" style="margin-bottom:10px">
							<el-button type="primary" @click="query" style="margin-left: 5px;">查询</el-button>
							<el-button @click="reset" style="margin-left: 15px;">重置</el-button>
						</div>
					</div>
					<div>
						<div style="width:150px">
							<el-link type="info" style="color: #666;"
								href="https://jusnn6k8al.feishu.cn/docs/doccnzCBHoyaEBH1TkTD21Cqvae" target="_blank">
								<i class="el-icon-document" style="margin-right: 3px;"></i>企微客户群功能说明
							</el-link>
						</div>
						<!-- <el-button type="text" style="color:black;margin-top:-20px" icon="el-icon-document">
              <a href="https://jusnn6k8al.feishu.cn/docs/doccnzCBHoyaEBH1TkTD21Cqvae" target="_blank">企微客户群功能说明</a>
            </el-button> -->
					</div>
				</div>
			</el-card>
			<el-card shadow="never" style="margin-top:10px">
				<buttonPermissions :datas="'clientGroupListsynchronous'" style="margin-bottom:10px">
					<div class="FlexRow">
						<el-popover placement="top" v-model="synchronousvisible">
							<div v-loading="synchronousLoading">
								<p>每24小时仅可同步1次企微客户群数据，是否确认继续同步？</p>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" @click="synchronousvisible = false">取消</el-button>
									<el-button type="primary" size="mini" @click="confirmsynchronous">确定</el-button>
								</div>
							</div>
							<el-button slot="reference" type="text" style="color:#606266;margin:0px;padding:0px" icon="el-icon-refresh">同步客户群数据</el-button>
						</el-popover>
					</div>
				</buttonPermissions>
				<div>
					<el-table :data="tableList" v-loading="tableloading">
						<el-table-column prop="" label="群名称" width="250px">
							<template slot-scope="scope">
								<div class="FlexRow">
									<div style="width:50px;height:50px">
										<img src="@/assets/img/50px.png" style="width:100%;height:100%" alt="" />
									</div>
									<div style="margin-left:10px;height:50px;display:table;">
										<div style="display:table-cell;vertical-align:middle">
											<div
												style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp:2;overflow: hidden;width:160px">
												{{scope.row.GroupName}}
											</div>
										</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="" label="群主">
							<template slot-scope="scope">
								<el-tag>
									<i class="el-icon-user"></i>
									{{scope.row.EmployeeName}}
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="AddTime" label="建群时间"></el-table-column>
						<el-table-column prop="GroupUserCount" label="当前群内人数">
							<template slot="header">
								<div class="FlexRow">
									<div>群内人数</div>
									<el-tooltip class="item" effect="dark" content="群内所有成员人数，包括企业微信员工和微信客户"
										placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
										|
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="GroupCustomerCount" label="当前群内客户数">
							<template slot="header">
								<div class="FlexRow">
									<div>群内客户数</div>
									<el-tooltip class="item" effect="dark" content="群内所有微信客户数" placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
										|
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="TodayJoinGroupCustomerCount" label="今日入群客户数">
							<template slot="header">
								<div class="FlexRow">
									<div>今日入群客户数</div>
									<el-tooltip class="item" effect="dark" content="今日加入群聊的微信客户数" placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
										|
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="TodayLosingGroupCustomerCount" label="今日退群客户数">
							<template slot="header">
								<div class="FlexRow">
									<div>今日退群客户数</div>
									<el-tooltip class="item" effect="dark" content="今日退出群聊的微信客户数" placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
										|
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="IsDissolution" label="群状态">
							<template slot-scope="scope">
								{{scope.row.IsDissolution?'已解散':'未解散'}}
							</template>
						</el-table-column>
						<el-table-column prop="" label="操作">
							<template slot-scope="scope">
								<el-button type="text" @click="jumpdetail(scope.row)">详情</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div style="margin-top:10px;text-align:right" v-if="total">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="sizepage"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		qyweixinrealgrouppagelist,
		customergroupstatis,
	} from "@/api/TurnTomySelf"
	import {
		qyweixinsuiteauthinfo,
		qyweixincustomersyncqyweixinrealgroup
	} from "@/api/sv1.0.0"
	import config from '@/config/index'
	import buttonPermissions from "@/components/buttonPermissions";
	export default {
		components:{
			buttonPermissions
		},
		data() {
			return {
				goUrls: config.GO_URL,
				tableloading: false,
				clientLoading: false,
				defaultImg: "https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png",
				groupImg: 'https://cdn.dkycn.cn/images/melyshop/grouplist.png',
				clientNum1: 0,
				clientNum2: 0,
				clientNum3: 0,
				clientNum4: 0,
				groupNmae: '',
				// TimeValue1: '',
				// TimeValue2: '',
				timeValue1: '',
				groupState: 1,
				groupList: [{
						label: '未解散',
						value: 1,
					},
					{
						label: '已解散',
						value: 2,
					},
				],
				tableList: [{
					id: 1
				}],
				currentPage: 1,
				sizepage: 20,
				total: null,

				isauthorization: true,
				
				synchronousvisible:false,
				synchronousLoading:false,
			}
		},
		created() {
			this.Isqyweixinsuiteauthinfo()
		},
		methods: {
			async confirmsynchronous(){
				try{
					this.synchronousLoading = true
					let res = await qyweixincustomersyncqyweixinrealgroup()
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.synchronousvisible = false
					}
				}finally{
					this.synchronousLoading = false
				}
			},
			jump() {
				this.$router.push({
					path: '/saleChannel/qyWechat'
				})
				// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#':'' 
				// let url = this.goUrls + headsUrls + '/saleChannel/qyWechat'
				// window.open(url)
			},
			//查看是否配置企业微信
			async Isqyweixinsuiteauthinfo() {
				this.pageloading = true
				try {
					let result = await qyweixinsuiteauthinfo({})
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.isauthorization = result.Result.IsFinishQyWeixinSuiteStp
						// this.isauthorization = false
						if (this.isauthorization) {
							this.getcustomergroupstatis()
							this.getqyweixinrealgrouppagelist()
						}
					}
				} finally {
					this.pageloading = false
				}
			},
			//查询
			query() {
				this.currentPage = 1
				this.getqyweixinrealgrouppagelist()
			},
			//跳转
			jumpdetail(e) {
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/Customer/clientBase/clientGroupDetail?Id=' + e.Id
				window.open(url)
			},
			handleSizeChange(e) {
				this.currentPage = 1
				this.sizepage = e
				this.getqyweixinrealgrouppagelist()
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.getqyweixinrealgrouppagelist()
			},
			//重置
			reset() {
				// console.log(this.TimeValue)
				// this.changetime(this.TimeValue[0])
				this.groupNmae = ''
				// this.TimeValue1 = ''
				// this.TimeValue2 = ''
				this.timeValue1 = '';
				this.groupState = 1
				this.cascaderValue = ["0", "0-1"]
				// console.log(this.TimeValue)
			},
			handleChange(e) {
				this.cascaderValue = e
			},
			changetime(time) {
				// console.log(time)
				if (time) {
					let year = time.getFullYear()
					let month = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
					let date = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()
					// console.log((year+'-'+month+'-'+date))
					return (year + '-' + month + '-' + date)
				}
			},
			//头部数据
			async getcustomergroupstatis() {
				this.clientLoading = true
				let result = await customergroupstatis({})
				if (result.IsSuccess) {
					// console.log(result.Result)
					this.clientNum1 = result.Result.AllGroupCount
					this.clientNum2 = result.Result.AllCustomerCount
					this.clientNum3 = result.Result.TodayJoinGroupCustomerCount
					this.clientNum4 = result.Result.TodayLosingGroupCustomerCount
				}
				this.clientLoading = false
			},
			//表格
			async getqyweixinrealgrouppagelist() {
				// console.log(this.changetime(this.TimeValue[0]),this.changetime(this.TimeValue[1]))
				this.tableloading = true
				let groupState = null
				if (this.groupState == 0) {
					groupState = null
				} else if (this.groupState == 1) {
					groupState = false
				} else if (this.groupState == 2) {
					groupState = true
				}
				let data = {
					Keywords: this.groupNmae,
					StartTime: this.timeValue1 ? this.changetime(this.timeValue1[0]):'',
					EndTime:this.timeValue1 ? (this.changetime(this.timeValue1[1]) + " 23:59:59"):'',
					IsDissolution: groupState,
					IsQyWeixinRealGroupSelect: false,
					Skip: (this.currentPage - 1) * this.sizepage,
					Take: this.sizepage
				}
				// console.log(data)
				let result = await qyweixinrealgrouppagelist(data)
				if (result.IsSuccess) {
					// console.log(result.Result)
					this.tableList = result.Result.Results
					this.total = result.Result.Total
				}
				this.tableloading = false
			},
		},
	}
</script>

<style scoped lang="less">
	.FlexRow {
		display: flex;
		flex-direction: row;
	}

	.blue-box {
		border: 1px solid #409EFF;
		background: #FBFDFF;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.InputFont {
		font-size: 14px;
		line-height: 37px
	}

	.theFontHidden {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	
	
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	
	.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 15px;
		background-color: #f8f8f9;
		
		
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
</style>
